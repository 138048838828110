import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'components/Markdown'
import contentfulImage from 'lib/contentful-image'
import Button from 'components/Button'
import { linkPropType } from 'lib/prop-types'

const ImageAndWords = props => {
  const imageUrl = contentfulImage(props.image.url, {
    width: 500,
    quality: 75,
  })

  const textPosition = props.position ? 'left' : 'right'

  return (
    <div className="section section--padded-big">
      <div className="section__inner">
        <h2 className="headline tc">{props.title}</h2>
        <div className={`media media--text-${textPosition}`}>
          <div className="media__figure">
            <div>
              <img className="media__img" src={imageUrl} />

              {props.link && (
                <Button
                  link={props.link}
                  className="margin--v2 btn btn--primary"
                >
                  {props.link.label}
                </Button>
              )}
            </div>

            <div>
              {props.secondaryImage && (
                <img
                  className="media__img"
                  src={contentfulImage(props.secondaryImage.url, {
                    width: 500,
                    quality: 75,
                  })}
                />
              )}

              {props.secondaryLink && (
                <Button
                  link={props.secondaryLink}
                  className="margin--v2 btn btn--primary"
                >
                  {props.secondaryLink.label}
                </Button>
              )}
            </div>
          </div>
          <div className="media__article">
            <Markdown>{props.text}</Markdown>
          </div>
        </div>
      </div>
    </div>
  )
}

ImageAndWords.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  secondaryImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  position: PropTypes.bool,
  link: linkPropType,
  secondaryLink: linkPropType,
}

export default ImageAndWords

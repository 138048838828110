// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import ImageAndWords from 'components/Contentful/ImageAndWords'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Online Monitoring der Wärmepumpe"
        meta={[
          { name: 'description', content: 'Aktive Überwachung, detaillierte Kontrolle und spezifischer Service der Wärmepumpe ermöglicht einen langfristig effizienten Betrieb. Jetzt abschliessen!' },
          { name: 'keywords', content: 'Online, Monitoring, Service, Überwachung' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--grey'
          key='6aJBUB4i78vCaRKX9D2utt-4RFn6pmYbkOfl9bJctExgc'
          id='monitoring'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "4RFn6pmYbkOfl9bJctExgc",
    "updatedAt": "2021-02-09T19:42:19.812Z",
    "title": "Wärmepumpen Monitoring",
    "size": "Mittel",
    "teaser": "Zur Wärmepumpe schauen lohnt sich - ganz einfach mit dem neuen Monitoring Angebot.",
    "image": {
        "description": "Einfamilienhaus im Naturstein-/Betonstil in Waldumgebung",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/59HERrI2LmquS6WsEywMce/e7196bf9d27afacc79704bfff37e4ec0/flo-pappert-201009.png"
    },
    "badge": {
        "type": "link",
        "id": "3ZzJV7W5ov2IY0ZLGLH5y9",
        "updatedAt": "2021-02-09T20:15:10.257Z",
        "label": "Empfohlen: Wärmepumpe aktives Monitoring",
        "url": "https://go.heizungsmacher.ch/monitoring"
    },
    "anchorName": "monitoring",
    "backgroundColor": "grau"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='6aJBUB4i78vCaRKX9D2utt-1cRsbyndjZMxGvbTCM5Pyn'
          id='monitoring-empfohlen'
        >
          <ImageAndWords
            {...{
    "type": "imageAndWords",
    "id": "1cRsbyndjZMxGvbTCM5Pyn",
    "updatedAt": "2021-05-19T07:30:53.902Z",
    "title": "Aktives Monitoring",
    "text": "<p><strong>Eine richtig eingestellte Wärmepumpe ist effizient und bringt den richtigen Komfort</strong>. Wir überwachen Ihre Wärmepumpe aktiv und können Serviceeinsätze frühzeitig koordinieren. Einstellungen nehmen wir für Sie vor - so läuft das. Die Lebensdauer einer Wärmepumpe hängt primär von zwei Faktoren ab: Anzahl Einschaltungen und Betriebsstunden. Stimmt dieses Verhältnis, steht einem effizienten Betrieb nichts im Weg.</p>\n<p><strong>Wir empfehlen diese und weitere betriebsrelevante Parameter aktiv zu überwachen, nur so kann ein optimierter Betrieb mit dem gewünschten Komfort sichergestellt werden.</strong></p>\n<p>ab CHF 190.-- pro Jahr zzgl. MwSt.</p>\n",
    "image": {
        "contentType": "image/svg+xml",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/6KSsjoAmclvwx6Ecth3flg/baf35bba04e861a6b4e254250ea8d310/Icon_Monitoring_copy-01.svg"
    },
    "position": true,
    "anchorName": "monitoring-empfohlen",
    "link": {
        "type": "link",
        "id": "3JLxdXdqi2DgXMzyNXttoT",
        "updatedAt": "2021-02-09T20:15:24.489Z",
        "label": "Aktives Monitoring bestellen",
        "url": "https://go.heizungsmacher.ch/monitoring"
    },
    "backgroundColor": "weiß"
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
